import React from 'react';
import PropTypes from 'prop-types';
import CountlyWrapper from './CountlyWrapper';

const ScenariosComponent = React.lazy(() => import('./components/Scenarios'));

const DailyDocumentPage = React.lazy(() =>
  import('./components/DailyDocument')
);

const MonthlyReportsPage = React.lazy(() =>
  import('./components/MontlyReport')
);

const FileUploadScenarioPage = React.lazy(() =>
  import('./components/FileUpload/DesadvUpload')
);

const DashboardScenarioPage = React.lazy(() =>
  import('./components/Dashboard')
);

const EnvelopePage = React.lazy(() =>
  import('./components/Envelopes/EnvelopeComponent')
);

const ViewDocumentSwitchComponent = React.lazy(() =>
  import('./components/Document/Order/View/ViewDocumentSwitch')
);

const CreateDocumentSwitchComponent = React.lazy(() =>
  import('./components/Document/Order/Create/CreateDocumentSwitch')
);
const WebEdiSettingsPageComponent = React.lazy(() =>
  import('./components/Settings/WebEdiSettingsPage')
);

const Components = {
  ScenariosComponent,
  DailyDocumentPage,
  MonthlyReportsPage,
  FileUploadScenarioPage,
  DashboardScenarioPage,
  EnvelopePage,
  ViewDocumentSwitchComponent,
  CreateDocumentSwitchComponent,
  WebEdiSettingsPageComponent,
};

const AsyncPage = ({componentName, ...props}) => {
  const Component = Components[componentName];

  if (!Component) {
    console.error(
      'Failed to find component',
      componentName,
      'in components object',
      Components,
      ', returning null.'
    );
    return null;
  }

  return (
    <>
      <CountlyWrapper />
      <Component {...props} />
    </>
  );
};

AsyncPage.propTypes = {
  componentName: PropTypes.string.isRequired,
};

export default AsyncPage;
