/* eslint-disable no-unused-vars */
import Countly from 'countly-sdk-web';
import {APP_ENV, determineEnvironmentFromLocation} from '@ecosio/components';
import {AxiosError} from 'axios';
import {CompanyTypes, CONTEXT_PATH} from 'src/constants';
import type {UserConfig} from 'src/types';

const app = 'webedi';
let disabled = true;

const IGNORED_EMAILS = ['@mailtest.ecosio.com', '@simulator.amazones.com'];

export enum MainCategory {
  ElementClick = 'Element click',
  ButtonClick = 'Button click',
  FilterClick = 'Filter click',
  InputField = 'Input field',
}

export interface AnalyticsSegmentation {
  mainCategory: MainCategory;
  companyUuid: string;
  companyName: string;
  // the optional thing that has been selected or text that has been entered
  item?: string;
  // an optional sub-category, e.g. the name of a form field or element
  // that has been clicked
  subCategory?: string;
}

const getRevision = (): string => {
  try {
    // @ts-expect-error TBD
    return import.meta.env.VITE_REVISION;
  } catch (error) {
    console.error(error);
    return 'Unknown';
  }
};

const shouldDisableAnalyticsForUser = (userConfig: UserConfig): boolean => {
  if (
    // we don't want to count e2e test users, but still need to e2e-test countly :)
    // we have a single user that works for tests, all others do not send events
    typeof IGNORED_EMAILS.find(
      (ignored) => userConfig.email.indexOf(ignored) > -1
    ) !== 'undefined' &&
    userConfig.email !== 'countly+eurotrade@mailtest.ecosio.com'
  ) {
    // don't track e2e test users
    return true;
  }

  //https://gitlab.ecosio.com/code/customer-apps/webedi/-/issues/1023
  // eslint-disable-next-line sonarjs/prefer-single-boolean-return
  if (
    userConfig?.company?.companyType === CompanyTypes.EXTERNAL &&
    !userConfig?.analyticsTrackingAccepted
  ) {
    console.info('Analytics tracking not accepted, disabling countly tracking');
    // don't track users, if the company type is EXTERNAL and analytics tracking is not approved
    return true;
  }

  return false;
};

export const setupSdk = (userConfig: UserConfig): boolean => {
  try {
    if (shouldDisableAnalyticsForUser(userConfig)) {
      return false;
    }

    const env = determineEnvironmentFromLocation(window.location.href);

    if (env === APP_ENV.DEV || env === APP_ENV.DOCKER) {
      return false;
    }

    disabled = false;

    let app_key = '41f70e78c3aa7d130c09506b71c3fe7bfe6d1b54';
    let url = 'https://ecosio-27eebab1ffca9.flex.countly.com';

    switch (env) {
      case APP_ENV.PROD:
        app_key = 'bc16ae65a8d4a25715583019278dc6cf42b2322f';
        url = 'https://ecosio-c7ab9d997f488.flex.countly.com';
        break;
      case APP_ENV.TEST:
        app_key = 'dbe02dd1eb8b25d6d42680f6da7ad3f42694e0fa';
        url = 'https://ecosio-c7ab9d997f488.flex.countly.com';
        break;
      case APP_ENV.DEV:
        app_key = '41f70e78c3aa7d130c09506b71c3fe7bfe6d1b54';
        url = 'https://ecosio-27eebab1ffca9.flex.countly.com';
        break;
    }

    Countly.init({
      app_key,
      url,
      debug: false,
      device_id: userConfig.email,
      clear_stored_id: true,
    });
    Countly.track_errors({version: getRevision()});
    Countly.track_sessions();
    trackUserDetails(userConfig);

    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const trackUserDetails = (userConfig: UserConfig): void => {
  if (disabled) {
    return;
  }

  try {
    Countly.q.push([
      'user_details',
      {
        name: `${userConfig.firstName} ${userConfig.lastName}`,
        username: userConfig.email,
        email: userConfig.email,
        organization: userConfig.company.name,
        custom: {
          selectedEdiPermission: userConfig.selectedEdiPermission,
        },
      },
    ]);
  } catch (error) {
    console.error(error);
  }
};

export const trackPageView = (
  url: string,
  companyUuid: string,
  companyName: string
): void => {
  if (disabled) {
    return;
  }

  // https://support.count.ly/hc/en-us/articles/360037441932-Web-analytics-JavaScript#h_01HABTQ437CAD08ESRK6RMJ2FG
  try {
    Countly.q.push(['track_pageview', `${CONTEXT_PATH}${url}`], null, {
      segmentation: {
        env: determineEnvironmentFromLocation(window?.location?.href),
        companyUuid,
        companyName,
        app,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const trackEvent = (
  name: string,
  segmentation: AnalyticsSegmentation
): void => {
  console.info('Track event: ', name, segmentation);

  if (disabled) {
    return;
  }

  try {
    const {companyUuid, companyName, ...rest} = segmentation;
    Countly.q.push([
      'add_event',
      {
        key: name,
        count: 1,
        segmentation: Object.assign(
          {
            env: determineEnvironmentFromLocation(window?.location?.href),
            companyUuid,
            companyName,
            app,
          },
          rest
        ),
      },
    ]);
  } catch (error) {
    console.error(error);
  }
};

export const trackError = (axiosError: AxiosError): void => {
  if (disabled) {
    return;
  }

  try {
    Countly.log_error(`axios error for url ${axiosError.config?.url ?? ''}`, {
      version: getRevision(),
      url: axiosError.config?.url,
      code: axiosError.code,
      method: axiosError.config?.method,
      status: axiosError.response?.status,
      responseBody: JSON.stringify(axiosError.response?.data),
    });
  } catch (error) {
    console.error(error);
  }
};
